.product-row-view {
  &:nth-child(2n) .product-row-view__row {
    background-color: $color-alabaster;
  }

  .triangle-up {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $color-brass;
  }

  .triangle-down {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid $color-brass;
  }
}
