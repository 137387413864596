.courses-page {
  .slick-dots {
    bottom: 15px;
  }

  .course {
    &.course--open .course__extra {
      width: calc(100% + 16px);

      @media all and (max-width: $bp-md) {
        width: 100%;
        height: 340px;
      }
    }
  }
}
