.building-slider {
  .slick-slider {
    position: relative;
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    top: 30%;
    height: 45px;
    width: 45px;
    background-color: $color-bright-red;
    z-index: 1;
    border-radius: 50%;
    font-size: 0;

    &::after {
      content: "";
      background: url(//d2h5lanwxwsz4l.cloudfront.net/assets/images/chevron.png);
      background-repeat: no-repeat;
      position: absolute;
      display: inline-block;
      height: 23px;
      width: 25px;
      background-size: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-position: 40%;
    }
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-next {
    right: -64px;

    &::after {
      transform: translate(-50%, -50%) rotateZ(180deg);
    }
  }

  .slick-prev {
    left: -64px;
  }

  @media all and (max-width: $bp-lg) {
    .slick-next {
      right: 24px;
    }

    .slick-prev {
      left: 24px;
    }
  }

  .slick-dots {
    bottom: 39%;

    @media all and (max-width: $bp-sm) {
      height: 0;
      overflow: hidden;
    }
  }
}