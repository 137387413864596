.primary-button {
  &:hover .primary-button__wrapper {
    padding-right: 2px;
    margin-left: 2px;

    .primary-button__link {
      margin-top: -2px;
      margin-bottom: 2px;
      padding-bottom: 2px;
    }
  }

  &.primary-button--disabled .primary-button__wrapper {
    padding-right: 0;
    margin-left: 4px;

    .primary-button__link {
      background-color: $color-shadow;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 4px;
    }
  }
}
