/****************
  Page transition animations
 */
.page-fade-in-leave.page-fade-in-leave-active,
.page-fade-in-enter.page-fade-in-enter-active {
  opacity: 0;
  transition: opacity 0s;
}

.page-fade-in-enter.page-fade-in-enter-active {
  opacity: 1;
}

.page-fade-in-leave {
  opacity: 0;
}

.can-animate {
  .page-transition {
    .page-fade-in-leave {
      opacity: 1;
    }

    .page-fade-in-leave.page-fade-in-leave-active {
      opacity: 0;
      transition: opacity 0.3s;
    }

    .page-fade-in-enter,
    .inner-page-fade-in-enter {
      opacity: 0;
    }

    .page-fade-in-enter.page-fade-in-enter-active {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
}

