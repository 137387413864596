*, *:before, *:after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: $color-background;
}

body.main {
  background-image: url(//d2h5lanwxwsz4l.cloudfront.net/assets/images/bg.jpg);
}

button {
  padding: 0;
  border: none;
  background: inherit;
  cursor: pointer;
  border-radius: 0;
  outline: none;
}

a {
  color: inherit;
  font-size: 16px;
  font-weight: 700;
  transition: color 0.3s, background 0.3s;
}

strong {
  font-weight: 700;
}

.slick-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;

  & > li {
    display: inline-block;

    button {
      font-size: 0px;
      height: 11px;
      width: 11px;
      margin: 5px;
      border: 1px solid $color-gray;
      border-radius: 50%;
      background-color: $color-white;
    }

    &.slick-active button {
      background-color: $color-gray;
    }
  }
}

.checkmark-icon::before {
  top: 11px;
  left: 7px;
  width: 10px;
  transform: rotateZ(45deg);
  content: "";
  position: absolute;
  height: 3px;
  background-color: #ffffff;
}

.checkmark-icon::after {
  top: 7px;
  left: 12px;
  width: 19px;
  transform: rotateZ(-45deg);
  content: "";
  position: absolute;
  height: 3px;
  background-color: #ffffff;
}

.user-entered-html {
  a {
    color: $color-brass;
    font-weight: 400;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  p {
    margin-bottom: 10px;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  ul {
    list-style-type: circle;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: 5px;
  }

  &.child-p-mb20 {
    p {
      margin-bottom: 20px;
    }
  }
}
