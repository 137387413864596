/**
 * Susy is used for creating grids.
 */

.grid-container {
  @include container($width-wrapper);
  // @include show-grid(overlay);

  &.--full-bleed {
    padding-left: 0;
    padding-right: 0;
  }

  @media all and (max-width: $bp-lg) {
    padding: $padding-grid-container;

    &.--full-bleed\@lg {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.grid-container--wide {
  @include container($width-wrapper-wide);
  // @include show-grid(overlay);
  @media all and (max-width: $bp-lg) {
    padding: $padding-grid-container;

    &.--full-bleed\@lg {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.grid-container--extra-wide {
  @include container($width-wrapper-extra-wide);
  // @include show-grid(overlay);
  @media all and (max-width: $bp-lg) {
    padding: $padding-grid-container;

    &.--full-bleed\@lg {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media all and (max-width: $bp-md) {
    @include container($width-wrapper-extra-wide-md);
  }
}

.span-2-of-10 {
  @include span(2 of 10);
}

@for $i from 1 through $grid-columns-count {
  .span-#{$i} {
    @include span($i);
  }

  .push-#{$i} {
    @include push($i);
  }

  .pull-#{$i} {
    @include pull($i);
  }

  .post-#{$i} {
    @include post($i);
  }
}

.gallery-columns-3 {
  .span-4:nth-child(3n+3) {
    @include last;
  }
}

.gallery-columns-4 {
  .span-3:nth-child(4n+4) {
    @include last;
  }
}

.span-last {
  @include last;
}

@media all and (max-width: $bp-lg) {
  @for $i from 1 through $grid-columns-count {
    .span-#{$i}\@lg {
      @include span($i);
    }

    .push-0\@lg {
      margin-left: 0;
    }

    .push-#{$i}\@lg {
      @include push($i);
    }

    .pull-#{$i}\@lg {
      @include pull($i);
    }

    .post-#{$i}\@lg {
      @include post($i);
    }
  }

  .span-last\@lg {
    @include last;
  }

  .span-last-clear\@lg {
    float: left;
    @include gutter();
  }
}

@media all and (max-width: $bp-md) {
  @for $i from 1 through $grid-columns-count {
    .span-#{$i}\@md {
      @include span($i);
    }

    .push-0\@md {
      margin-left: 0;
    }

    .push-#{$i}\@md {
      @include push($i);
    }

    .pull-#{$i}\@md {
      @include pull($i);
    }

    .post-#{$i}\@md {
      @include post($i);
    }
  }

  .gallery-columns-3 {
    .span-4:nth-child(3n+3) {
      float: left;
      @include gutter();
    }

    .span-6\@md:nth-child(2n+2) {
      @include last;
    }
  }

  .gallery-columns-4 {
    .span-3:nth-child(4n+4) {
      float: left;
      @include gutter();
    }

    .span-4\@md:nth-child(3n+3) {
      @include last;
    }
  }

  .span-last\@md {
    @include last;
  }

  .span-last-clear\@md {
    float: left;
    @include gutter();
  }
}

@media all and (max-width: $bp-sm) {
  @for $i from 1 through $grid-columns-count {
    .span-#{$i}\@sm {
      @include span($i);
    }

    .push-0\@sm {
      margin-left: 0;
    }

    .push-#{$i}\@sm {
      @include push($i);
    }

    .pull-#{$i}\@sm {
      @include pull($i);
    }
  }

  .gallery-columns-3 {
    .span-4\@md:nth-child(2n+2) {
      float: left;
      @include gutter();
    }
  }

  .gallery-columns-4 {
    .span-4\@md:nth-child(3n+3) {
      float: left;
      @include gutter();
    }

    .span-6\@sm:nth-child(2n+2) {
      @include last;
    }
  }

  .span-last\@sm {
    @include last;
  }

  .span-last-clear\@sm {
    float: left;
    @include gutter();
  }
}

@media all and (max-width: $bp-smx) {
  @for $i from 1 through $grid-columns-count {
    .span-#{$i}\@smx {
      @include span($i);
    }

    .push-0\@smx {
      margin-left: 0;
    }

    .push-#{$i}\@smx {
      @include push($i);
    }

    .pull-#{$i}\@smx {
      @include pull($i);
    }
  }

  .gallery-columns-3 {
    .span-4\@md:nth-child(2n+2) {
      float: left;
      @include gutter();
    }
  }

  .gallery-columns-4 {
    .span-4\@md:nth-child(3n+3) {
      float: left;
      @include gutter();
    }

    .span-6\@smx:nth-child(2n+2) {
      @include last;
    }
  }

  .span-last\@smx {
    @include last;
  }

  .span-last-clear\@smx {
    float: left;
    @include gutter();
  }
}